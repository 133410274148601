import React, { useRef, useState, useEffect } from 'react';

import { BTNAudio, BTNPause, BTNPlay } from '../../Atoms/Icons/Icons';
import useMedias from '../../hooks/useMedias';

import './Video.scss';

let classNames = require('classnames');

const VideoMP4 = ({ cover, videoLink, title }) => {
  const { processUrl } = useMedias();

  const vidRef = useRef(null);

  let player = null;
  const [duration, setDuration] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [currentTime, setCurrentTime] = useState(false);
  const [muted, setMuted] = useState(false);
  const [volume, setVolume] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    player = vidRef.current;

    document.body.onkeypress = (e) => {
      if (e.keyCode == 32 && playing) {
        e.preventDefault();
        onPause();
      }
    };
  }, []);

  const onLoadedMetadata = (e) => {
    if (player) { setDuration(player.duration); }
    setVideoLoaded(true);
  };

  const onPlay = (event) => {
    setDuration(player.duration);
    player.play();
    setPlaying(true);
    window.timer = setInterval(() => {
      setCurrentTime(player.currentTime);
      setProgress((player.currentTime / player.duration) * 100);
    }, 300);
  };
  const onPause = () => {
    player.pause();
    setPlaying(false);
    clearInterval(window.timer);
  };
  const onEnd = () => {
    setPlaying(false);
    clearInterval(window.timer);
    setProgress(100);
  };

  const getMinSec = (time) => {
    let min = parseInt(time / 60, 10);
    let sec = parseInt(time - min * 60);
    let return_value = min > 0 ? min + "'" : '';
    return return_value + sec + "''";
  };

  const onMuteToogle = () => {
    player.muted = !muted;
    setMuted(!muted);
    setVolume(player.muted ? 10 : 0.5);
    player.volume = player.muted ? 0 : 0.5;
  };

  const onProgrssbarChange = (e) => {
    const value = parseInt(e.currentTarget.value, 10);
    setProgress(value);
    const new_second = (duration * value) / 100;
    player.currentTime = new_second;
    onPlay();
  };

  const onVolumeChange = (e) => {
    const value = parseInt(e.currentTarget.value, 10);
    setVolume(value);
    player.volume = (10 - value) / 10;
  };

  return (
    <div className="video_container">
      <div className="video_wrapper">
        <video
          ref={vidRef}
          controls={false}
          autoPlay={false}
          onLoadedMetadata={onLoadedMetadata}
          onEnded={onEnd}
          title={title}
        >
          <source src={processUrl(videoLink)} type="video/mp4" />
          Video MP4 is not compatible with your browser
        </video>
      </div>
      <div className="cover_controls">
        <div className={classNames('cover', playing ? 'hide' : null)}>
          <img
            src={processUrl(cover?.image_style_uri?.gatsby_medium)}
            alt={title}
            style={{ objectFit: 'cover' }}
          />
        </div>
        <div className="controls">
          <a
            className={classNames('btn_play', { disabled: !videoLoaded })}
            onClick={playing ? onPause : onPlay}
            onKeyUp={(e) => {
              if (e.keyCode === 13) {
                e.currentTarget.click();
              }
            }}
            tabIndex={0}
            aria-label={playing ? 'Mettre en pause la video' : 'Lire la video'}
          >
            {playing ? (
              <BTNPause className="btn_pause" color="color_yello" />
            ) : (
              <BTNPlay className="btn_play" />
            )}
          </a>
          <div className="progressbar">
            <input
              type="range"
              value={progress}
              onChange={onProgrssbarChange}
            />
          </div>
          <p className="time">{getMinSec(duration - currentTime)}</p>
          <div className="btn_audio">
            <a
              onClick={onMuteToogle}
              tabIndex={0}
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  e.currentTarget.click();
                }
              }}
            >
              <BTNAudio
                className={classNames('btn_mute', { muted: muted })}
                color="color_yellow"
              />
            </a>
            <input
              type="range"
              orient="vertical"
              min="0"
              max="10"
              step="1"
              value={volume}
              onChange={onVolumeChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoMP4;
